import React from "react";
import "./Styles.scss";
import charityBox from "./constant";

const checkUrl = (url) => {
  if(url === null || url===undefined){
    return null;
  }
  if (url && (url.includes('http://') || url.includes('https://'))) {
    return url;
  }
  return `http://${url}`;
}


const InfoCard = ({
  title,
  name,
  bgColor,
  width,
  height,
  className,
  cardName,
  cardTitle,
  imageSrc,
  imageWidth,
  imageHeight,
  cardImageStyle,
  href
}) => {

  return (
    <a
      className={`Infocard ${className ? className : ""}`}
      href={className === charityBox ? checkUrl(href) : null}
      style={{ background: bgColor, width: width, height: height }}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="flex-group">
        <div className={`Infocard-title ${cardTitle ? cardTitle : ""}`}>
          {title}
        </div>
        <img
          className={`Infocard-image ${cardImageStyle ? cardImageStyle : ""}`}
          src={imageSrc}
          alt="card"
          width={imageWidth}
          height={imageHeight}
        />
      </div>
      <div className={`Infocard-name ${cardName ? cardName : ""}`}>{name}</div>
    </a>
  );
};

export default InfoCard;
