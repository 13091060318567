import React from "react";
import * as Constant from "./constant";
import "./style.scss";

const RaffleWinner = ({ name }) => {
  const { firstName, lastName } = name;
  return (
    <div className="raffle-winner">
      <div className="raffle-won-by">{Constant.RaffleWonBy}</div>
      <div className="raffle-winner-name">
        {firstName} {lastName[0]}
      </div>
      <div className="raffle-winner-terms-and-conditions">
        {Constant.ClickHereFor}
        <a
          className="terms-and-conditions"
          href="https://www.lunchwith4interestingpeople.com/termsofservice.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          {Constant.TermsConditions}
        </a>
      </div>
    </div>
  );
};

export default RaffleWinner;
