import React, {Component} from "react";
import "./style.scss";

class Loading extends Component {

    static Main = () => (
        <div className="loading-main">
        </div>
    );

    static Nav = () => (
        <div className="loading-nav"></div>
    )

    static Card = () => (
        <div className="loading-card">
            <div className="loading-small-card"></div>
            <div className="loading-small-card"></div>
        </div>
    )

    static Image = () => (
        <div className="loading-image">
        </div>
    )

    render(){
        const {children} = this.props;
        return children;
    }
}

export default Loading;