import React from "react";
import * as Constant from "./constant";
import "./style.scss";

const RaffleClosed = () => {
  return (
    <div className="raffle-closed-container">
      <div className="raffle-closed-container-text">
        {Constant.RaffleClosedText}
      </div>
      <div className="raffle-closed-container-willbe-notified">
        {Constant.WillBeNotified}
      </div>
      <div className="raffle-closed-container-terms-and-conditions">
        {Constant.ClickHereFor}
        <a
          className="terms-and-conditions"
          href="https://www.lunchwith4interestingpeople.com/termsofservice.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          {Constant.TermsConditions}
        </a>
      </div>
    </div>
  );
};

export default RaffleClosed;
