import React from "react";
import * as Constant from "./constant";
import GenericInput from "../GenericInput";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import Button from "../Button";
import "react-phone-number-input/style.css";
import { client } from "../../App";
import "./style.scss";
import { useLocation } from "react-router-dom";
import { initiateRafflePurchaseProcess } from "../../Queries/detailQuery";
import stripeConfig from "../../Config/stripe-config";
import { loadStripe } from "@stripe/stripe-js";
import Loader from "react-loader-spinner";

const ModalForMobileContent = ({ state, dispatch }) => {
  const { search } = useLocation();
  console.log(state);
  /***
   *
   */

  const handlePhoneNumber = (phoneNumber) => {
    if (isValidPhoneNumber(phoneNumber)) {
      dispatch({
        type: "MOBILE_MODAL_PHONENUMBER",
        payload: { phoneNumber: phoneNumber, error: false, target: null },
      });
    } else {
      dispatch({
        type: "MOBILE_MODAL_PHONENUMBER",
        payload: {
          phoneNumber: phoneNumber,
          error: true,
          target: "phone number",
        },
      });
    }
  };

  const handlePayment = () => {
    const url = new URLSearchParams(search);
    const causeId = parseInt(url.get("causeid"));
    dispatch({ type: "START_LOADING", payload: true });
    client
      .mutate({
        mutation: initiateRafflePurchaseProcess,
        variables: {
          input: {
            firstName: state.mobile_modal_firstName,
            lastName: state.mobile_modal_lastName,
            email: state.mobile_modal_email,
            phoneNumber: state.mobile_modal_phoneNumber,
            amount:
              state.bottom_sheet_amount === ""
                ? parseInt(state.bottom_sheet_isSelected)
                : parseInt(state.bottom_sheet_amount),
            lunchForCauseId: causeId,
            isParticipatingInRaffle: state.bottom_sheet_checked,
          },
        },
      })
      .then((result) => {
        const {
          data: {
            initiateRafflePurchaseProcess: { message, code, data },
          },
        } = result;

        if (code === "200" && data) {
          dispatch({
            type: "CLEAR_DATA_AFTER_RESPONSE_STRIPE",
            payload: {
              firstName: "",
              lastName: "",
              phoneNumber: "",
              email: "",
              responseMessage: "",
            },
          });
          const callStripe = async () => {
            const stripe = await loadStripe(stripeConfig.public_key);
            const stripeObject = await stripe.redirectToCheckout({
              sessionId: data,
            });
            console.log(stripeObject);
          };
          callStripe();
          dispatch({ type: "START_LOADING", payload: false });
        } else {
          dispatch({ type: "SET_PAYMENT_RESPONSE_MESSAGE", payload: message });
          dispatch({ type: "START_LOADING", payload: false });
        }
      });
  };

  const handleChange = (e) => {
    switch (e.target.name) {
      case "email":
        if (
          e.target.value.match(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
        ) {
          dispatch({
            type: "MOBILE_MODAL_EMAIL",
            payload: { email: e.target.value, error: false, target: null },
          });
        } else {
          dispatch({
            type: "MOBILE_MODAL_EMAIL",
            payload: {
              email: e.target.value,
              error: true,
              target: "email",
            },
          });
        }
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="modal-for-mobile-content-container">
        <div className="modal-for-mobile-content-container-title">
          {Constant.basicDetails.toString()}
        </div>
        <div className="modal-for-mobile-content-container-text">
          {Constant.fullName.toUpperCase()}
        </div>
        <div>
          <GenericInput
            className="modal-for-mobile-content-container-text-input"
            type="text"
            value={state.mobile_modal_firstName}
            name="firstName"
            onChange={(e) =>
              dispatch({
                type: "MOBILE_MODAL_FIRSTNAME",
                payload: e.target.value,
              })
            }
          />
        </div>
        <div>
          <GenericInput
            className="modal-for-mobile-content-container-text-input"
            type="text"
            value={state.mobile_modal_lastName}
            name="lastName"
            onChange={(e) =>
              dispatch({
                type: "MOBILE_MODAL_LASTNAME",
                payload: e.target.value,
              })
            }
          />
        </div>
        <div className="modal-for-mobile-content-container-text">
          {Constant.email.toUpperCase()}
        </div>
        <div>
          <GenericInput
            className="modal-for-mobile-content-container-text-input"
            type="text"
            name="email"
            value={state.mobile_modal_email}
            onChange={handleChange}
          />
        </div>
        <div className="modal-for-mobile-content-container-text">
          {Constant.phoneNumber.toUpperCase()}
        </div>
        <div>
          <PhoneInput
            value={state.mobile_modal_phoneNumber}
            onChange={handlePhoneNumber}
            error={
              state.mobile_modal_phoneNumber
                ? isValidPhoneNumber(state.mobile_modal_phoneNumber)
                  ? undefined
                  : "Invalid phone number"
                : "Phone number required"
            }
            international
            placeholder=""
            className="modal-for-mobile-content-container-phone-number-input"
          />
          <div>
            {state.mobile_modal_error && (
              <div className="modal-error">
                Please enter a valid {state.mobile_modal_target} {state.mobile_modal_target === "phone number" && <div>{Constant.withCountryCode}</div>}
              </div>
            )}
          </div>
        </div>
        <div className="modal-for-mobile-content-container-proceed-to-pay">
          <Button
            onClick={handlePayment}
            className={`proceed-to-pay-button disabled ${
              !state.mobile_modal_error &&
              state.mobile_modal_email !== "" &&
              state.mobile_modal_firstName !== "" &&
              state.mobile_modal_phoneNumber !== "" && isValidPhoneNumber(state.mobile_modal_phoneNumber) &&
              state.mobile_modal_lastName !== "" &&
              "modal-enable"
            }`}
          >
            <div className="proceed-to-pay-button-text">
            {Constant.proceedToPay}{" "}
            {state.loading && (
              <div className="loader"><Loader type="TailSpin" color="#ffffff" height="20" width="20" /></div>
            )}
            </div>
          </Button>
        </div>
      </div>
    </>
  );
};

export default ModalForMobileContent;
