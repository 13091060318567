import React, { PureComponent } from "react";
import "./style.scss";
import RejectImage from "../../Assets/images/rejectedlunch.png";

const Modal = ({ children, open, responseMessage }) =>
  open && (
    <StyledModal>
      <StyledModal.ModalWrapper>
        <StyledModal.ModalBody>{children}</StyledModal.ModalBody>
        {responseMessage && (
          <div className="modal-response-message-container">
            <div className="modal-response-message">
              <img src={RejectImage} className="reject-image" alt="reject" />
              {responseMessage}
            </div>
          </div>
        )}
      </StyledModal.ModalWrapper>
    </StyledModal>
  );

class StyledModal extends PureComponent {
  static ModalWrapper = ({ children }) => (
    <div className="modal-wrapper">{children}</div>
  );

  static ModalBody = ({ children }) => (
    <div className="modal-body">{children}</div>
  );

  render() {
    return this.props.children;
  }
}

export default Modal;
