import React from "react";
import "./style.scss";
import { useLocation } from "react-router-dom";
import getCheckoutSessionMetaData from "../../Queries/paymentQuery";
import { Query } from "react-apollo";
import Loading from "../../components/Loading";

export const PaymentContext = React.createContext();

export const PaymentContainer = ({ children }) => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const sessionid = queryParams.get("session_id");
  
  return (
    <Query
      query={getCheckoutSessionMetaData}
      variables={{ SessionId: sessionid }}
    >
      {({ loading, data, error }) => {
        console.log(loading);
        console.log(data);
        console.log(error);
        if (loading) {
          return <Loading.Main />;
        } else if (data) {
          console.log(data)
          return (
            <PaymentContext.Provider value={data}>
              <div className="payment-container">{children}</div>
            </PaymentContext.Provider>
          );
        } else if (error) {
          console.error(error);
        }
      }}
    </Query>
  );
};

