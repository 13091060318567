const RaffleCancelled = "Raffle Cancelled";
const CancelledLine = "Due to some reason raffle has been cancelled. ";
const TermsAndConditionLine =
  "If you already donated, your donation will be applied to ";
const AsPer = "as per the";
const TermsConditions = "Terms and Conditions";
const LunchWith = "Lunch with "
export {
  RaffleCancelled,
  CancelledLine,
  TermsAndConditionLine,
  AsPer,
  TermsConditions,
  LunchWith
};
