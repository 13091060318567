const RaffleClosedText = "Raffle Closed";
const WillBeNotified = "Winners will be notified via messages. ";
const TermsAndConditionLine =
  "If you already donated, your donation will be applied to ";
const AsPer = "as per the";
const TermsConditions = "Terms and Conditions";
const ClickHereFor = "Click here for ";
export {
  RaffleClosedText,
  WillBeNotified,
  TermsAndConditionLine,
  AsPer,
  TermsConditions,
  ClickHereFor
};
