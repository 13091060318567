import React from "react";
import CircularImage from "../CircularImage";
import RaffleWinner from "../RaffleWinner";
import ClockImage from "../ClockImage";
import RaffleClosed from "../RaffleClosed";
import CancelledImage from "../CancelledImage";
import RaffleCancelled from "../RaffleCancelled";
import DownloadButtons from "../DownloadButtons";
import {isMobileOnly} from "react-device-detect";
import "./style.scss";
const StatusImage = ({ image, className, name, status }) => (
  <div
    style={image && { backgroundImage: `url(${image})` }}
    className={className ? className : ""}
  >
    {status === "raffleDrawn" ? (
      <>
        <CircularImage />
        <RaffleWinner name={name} />
        {!isMobileOnly &&
        <div className="download-button-container">
          <DownloadButtons title="Download Android and iOS app" />
        </div>
        }
      </>
    ) : status === "closed" ? (
      <>
        <ClockImage />
        <RaffleClosed />
        {!isMobileOnly &&
        <div className="download-button-container">
          <DownloadButtons title="Download Android and iOS app" />
        </div>
        }
      </>
    ) : status === "cancelled" ? (
      <>
        <CancelledImage />
        <RaffleCancelled />
        {!isMobileOnly &&
        <div className="download-button-container">
          <DownloadButtons title="Download Android and iOS app" />
        </div>
        }
      </>
    ) : (
      <></>
    )}
  </div>
);

export default StatusImage;
