import React, { useContext } from "react";
import {LunchForCauseDetailContext} from "../../container/CardContainer";
import * as Constant from "./constant";
import "./style.scss";
const RaffleCancelled = () => {
  const detail = useContext(LunchForCauseDetailContext);
  const {
    data:{getLunchForCauseDetails: { data }}
  } = detail;

  return (
    <div className="raffle-cancelled-container">
      <div className="raffle-cancelled-container-tagline">
        {Constant.RaffleCancelled}
      </div>
      <div className="raffle-cancelled-container-terms-and-conditions">
        {`${Constant.TermsAndConditionLine} ${Constant.LunchWith} ${data.celebrity.name} ${Constant.AsPer}`}{" "}
        <a
          className="terms-and-conditions"
          href="https://www.lunchwith4interestingpeople.com/termsofservice.html"
          target="_blank" rel="noopener noreferrer"
        >
          {Constant.TermsConditions}
        </a>
      </div>
    </div>
  );
};

export default RaffleCancelled;
