import React, { Component } from "react";
import "./style.scss";
import { Storage } from "aws-amplify";
import { LunchForCauseDetailContext } from "../../container/CardContainer"
import DefaultHostImage from "../../Assets/images/empty-banner-image-1@3x.png";
import Loading from "../Loading";

class HostImage extends Component {

  state ={
    image : DefaultHostImage,
    loading: false
  }

  static contextType = LunchForCauseDetailContext;

  getLunchImage = async () => {
    this.setState({loading:true});
    const {data:{getLunchForCauseDetails : {data:{imagePath}}}}=this.context;
    const imgSrc=await Storage.get(imagePath, {level: "public"})
    if(this.mounted && imagePath){
      this.setState({image: imgSrc});
    }
  }

  componentDidMount(){
    this.mounted = true;
    this.getLunchImage();
    this.setState({loading:false})
  }

  componentWillUnmount(){
    this.mounted=false;
  }

  render() {
    const {image, loading} = this.state;
    return (
      <div className="host-image-container">
        {loading ? <Loading><Loading.Image /></Loading> :
        <img src={image} alt="Host" className="host-image" />}
      </div>
    );
  }
}
export default HostImage;
