import React from "react";
import NavBar from "../../components/NavBar";
import Logo from "../../components/Logo";
import { PaymentContainer } from "../PaymentContainer";
import { LeftContainer } from "../LeftContainer";
import RightContainer from "../RightContainer";
import Title from "../../components/Title";
import PaymentCard from "../../components/PaymentCard";
import KeepTrackOfPayments from "../../components/KeepTrackOfPayments";
import GetAccessToAllYourCauses from "../../components/GetAccessToAllYourCauses";
import ExploreMoreCauses from "../../components/ExploreMoreCauses";
import DownloadButtons from "../../components/DownloadButtons";
import {isMobileOnly} from "react-device-detect";

const payment = () => {
  return (
    <>
      <NavBar>
        <Logo />
      </NavBar>
      <PaymentContainer>
        <LeftContainer>
          <Title />
          <PaymentCard />
          {isMobileOnly && <DownloadButtons title="Download Android and iOS app" />}
        </LeftContainer>
        <RightContainer>
          {!isMobileOnly && <DownloadButtons title="Download Android and iOS app" />}
          <KeepTrackOfPayments />
          <GetAccessToAllYourCauses />
          <ExploreMoreCauses />
        </RightContainer>
      </PaymentContainer>
    </>
  );
};

export default payment;
