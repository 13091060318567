import React from "react";
import Window from "./container/Window";
import Payment from "./container/Payment";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
const AppRoutes = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Window} />
        <Route exact path="/payment" component={Payment} />
      </Switch>
    </Router>
  );
};

export default AppRoutes;
