import React from "react";
import RaffleClosedImage from "../../Assets/images/raffle-closed.png";
import RaffleClosedImageForMobile from "../../Assets/images/raffle-closed-mobile.png";

const ClockImage = () => (
  <div className="clock-image-conatiner">
    <picture>
      <source
        srcSet={`${RaffleClosedImageForMobile}`}
        media="all and (max-width:479px)"
        alt="clock"
        type="image/png"
      />
      <img
        src={RaffleClosedImage}
        alt="clock"
        className="raffle-closed-image"
      />
    </picture>
  </div>
);

export default ClockImage;
