import React from "react";
import keepTrackOfPayments from "./constant";
import KeepTrackOfPaymentsImage from "../../Assets/images/transactions-img.png";
import "./style.scss"; 
const KeepTrackOfPayments = () => {
  return (
    <div className="keep-track-of-payments">
      <div>
      <div className="keep-track-of-payments-header">{keepTrackOfPayments.header}</div>
      <div className="keep-track-of-payments-description">{keepTrackOfPayments.description}</div>
      </div>
      <img className="keep-track-of-payments-image" src={KeepTrackOfPaymentsImage} alt="transactions"/>
    </div>
  );
};

export default KeepTrackOfPayments;
