import React, { useContext } from "react";
import HostImage from "../HostImage";
import { LunchForCauseDetailContext } from "../../container/CardContainer";
import InfoCard from "../InfoCard";
import {
  ContributorCount,
  RaffleDateAndTime,
} from "../LunchForCausePublishedDetails";
import * as Constant from "./constant";
import HeartImage from "../../Assets/images/charity-added@2x.png";
import StarImage from "../../Assets/images/celebrity-added@2x.png";
import { isMobileOnly } from "react-device-detect";
import moment from "moment";
import "./style.scss";

const HostCharityAndCelebrityForRaffleDrawnAndClosed = ({
  raffleDrawn,
  closed,
  cancelled,
}) => {
  const {
    data: {
      getLunchForCauseDetails: { data },
    },
  } = useContext(LunchForCauseDetailContext);
  return (
    <div className="host-charity-celebrity-raffle-drawn-closed-container">
      <HostImage />
      <div className="title">{data.title}</div>
      <div className="benefit">{data.benefit}</div>
      {raffleDrawn && (
        <RaffleDateAndTime
          noRaffleLine
          raffleWinnerAnnounced
          raffleDrawDate={data.raffleDrawDate}
        />
      )}
      {closed && (
        <RaffleDateAndTime
          closedAt={moment(data.raffleEndDate).isBefore(moment().toISOString())}
          closedAtDate={data.raffleEndDate}
        />
      )}
      {cancelled && (
        <RaffleDateAndTime cancelledAt={data.cancelledAt} noRaffleLine />
      )}
      <ContributorCount contributorsCount={data.contributorsCount} />
      {!isMobileOnly && (
        <div className="info-card-container">
          <InfoCard
            className="charity-card"
            name={
              data.charityContactDetailsForLunch &&
              data.charityContactDetailsForLunch.charity.title
            }
            bgColor="#DA453C"
            title={Constant.Charity.toUpperCase()}
            imageSrc={HeartImage}
            imageWidth="16px"
            imageHeight="15px"
            href={data.charityContactDetailsForLunch.charity.url}
          ></InfoCard>
          <InfoCard
            className="celebrity-card"
            cardTitle="celebrity-card-width"
            name={data.celebrity.name}
            bgColor="#FF9738"
            title={Constant.Celebrity.toUpperCase()}
            imageSrc={StarImage}
            imageWidth="16px"
            imageHeight="15px"
          ></InfoCard>
        </div>
      )}
    </div>
  );
};

export default HostCharityAndCelebrityForRaffleDrawnAndClosed;
