import React, { useContext } from "react";
import RadioButton from "../RadioButton";
import { LunchForCauseDetailContext } from "../../container/CardContainer";
import { WantToParticipateInRaffle } from "../LunchForCausePublishedDetails";
// import Form from "../Form";
import Button from "../Button";
import Input from "../Input";
import * as Constant from "./constant";
import "./style.scss";
const BottomSheetContent = ({ state, dispatch }) => {
  const {
    data: {
      getLunchForCauseDetails: { data },
    },
  } = useContext(LunchForCauseDetailContext);

  data.possibleRaffleAmount.sort((item1, item2) => item1 - item2);

  const handleSelected = (e) => {
    dispatch({
      type: "BOTTOM_SHEET_ISSELECTED",
      payload: { selected: e.target.value, error: false },
    });
  };

  const handleSelectedOther = (e) => {
    dispatch({
      type: "BOTTOM_SHEET_ISSELECTED",
      payload: { selected: e.target.value, error: "no-error" },
    });
  };

  const handleChecked = (e) => {
    dispatch({ type: "BOTTOM_SHEET_CHECKED", payload: !e.target.checked });
  };

  const handleClose = () => {
    dispatch({
      type: "BOTTOM_SHEET_CLOSE",
      payload: {
        open: false,
        selected: "",
        checked: true,
        error: null,
        valueError: false,
        amount:""
      },
    });
  };

  const handleInput = (e) => {
    if (
      e.target.value >= data.possibleRaffleAmount[0] &&
      e.target.value <= 99999
    ) {
      dispatch({
        type: "BOTTOM_SHEET_INPUT",
        payload: { amount: e.target.value, error: false },
      });
    } else if (e.target.value > 99999) {
      dispatch({
        type: "BOTTOM_SHEET_INPUT",
        payload: { amount: e.target.value, error: true, valueError: true },
      });
    } else {
      dispatch({
        type: "BOTTOM_SHEET_INPUT",
        payload: { amount: e.target.value, error: true },
      });
    }
  };

  const openUserDetailModal = () => {
    dispatch({ type: "BOTTOM_SHEET_OPEN_USER_MODAL", payload: true });
  };

  return (
    <div className="bottom-sheet-content-container">
      <div className="bottom-sheet-content-container-title">
        {Constant.selectRaffleAmount}
      </div>
      {/* <Form onSubmit={handleSubmit}> */}
      <div className="flex">
        {data.possibleRaffleAmount.map((item, index) => {
          return (
            <RadioButton
              key={index}
              amount={item}
              id={index}
              isSelected={state.bottom_sheet_isSelected === item.toString()}
              onChange={handleSelected}
            />
          );
        })}
        <RadioButton
          id={"other"}
          amount={"Other"}
          isSelected={state.bottom_sheet_isSelected === "Other"}
          onChange={handleSelectedOther}
        />
      </div>
      <div className="input-amount">
        {state.bottom_sheet_isSelected === "Other" && (
          <div className="input-amount-flex">
            {state.bottom_sheet_amount !== "" && (
              <div className="dollar-sign">$</div>
            )}
            <Input
              amount="Amount"
              value={state.bottom_sheet_amount}
              handleInput={handleInput}
              error={state.bottom_sheet_textbox_error}
            />
          </div>
        )}
      </div>
      {state.bottom_sheet_isSelected === "Other" && (
        <div className="value-error-text-conatiner">
          <div className="value-error-text">
            {state.bottom_sheet_value_error && <>{Constant.valueErrorText}</>}
          </div>
        </div>
      )}
      {state.bottom_sheet_isSelected === "Other" && (
        <div className="minimum-amount-container">
          <div className="minimum-amount">
            {Constant.minimumAmount + `$${data.possibleRaffleAmount[0]}`}
          </div>
        </div>
      )}
      <WantToParticipateInRaffle
        checked={state.bottom_sheet_checked}
        onChange={handleChecked}
        className="bottom-sheet-checkbox"
      />
      <div className="button-container">
        <Button className="back-button" onClick={handleClose}>
          {Constant.cancel}
        </Button>
        <Button
          type="button"
          onClick={openUserDetailModal}
          className={`proceed-button ${
            state.bottom_sheet_textbox_error ||
            state.bottom_sheet_textbox_error === null
              ? "disabled"
              : "enabled"
          } `}
        >
          {Constant.proceed}
        </Button>
      </div>
      {/* </Form> */}
    </div>
  );
};

export default BottomSheetContent;
