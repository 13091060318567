import React from "react";
import "./style.scss";
const RadioButton = ({amount, id, onChange, isSelected}) => (
    <div className="radio-button">
        <input
        id={id}
        onChange={onChange}
        value={amount}
        type="radio"
        checked={isSelected}
        />
        <label htmlFor={id}>{amount !== "Other" ? `$${amount}` : `${amount}`}</label>
    </div>
);

export default RadioButton;