import React from "react";
import NavBar from "../../components/NavBar";
import Logo from "../../components/Logo";
import Main from "../../components/Main";
import { CardContainer } from "../CardContainer";
import BottomSheet from "../../components/BottomSheet";
import GetStatusAndRender from "../../components/GetStatusAndRender";
import BottomSheetContent from "../../components/BottomSheetContent";
import ModalForMobile from "../../components/ModalForMobile";
import ModalForMobileContent from "../../components/ModalForMobileContent";
import { isMobileOnly } from "react-device-detect";

const Window = () => {
  return (
    <>
      <NavBar>
        <Logo />
      </NavBar>

      <Main>
        <CardContainer>
          {isMobileOnly && (
            <BottomSheet>
              {(state, dispatch) => {
                return <BottomSheetContent state={state} dispatch={dispatch} />;
              }}
            </BottomSheet>
          )}
          {isMobileOnly && (
            <ModalForMobile>
              {(state, dispatch) => {
                return (
                  <ModalForMobileContent state={state} dispatch={dispatch} />
                );
              }}
            </ModalForMobile>
          )}
          <GetStatusAndRender />
        </CardContainer>
      </Main>
    </>
  );
};

export default Window;
