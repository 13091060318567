import React from "react";
import "./style.scss";
const DownloadButtons = ({ title }) => {
  return (
    <>
      <div className="download-button-title">{title}</div>
      <div className="downloadButtons">
        <div className="download-button d-flex flex-row justify-content-center margin-bottom">
          <div className="buttons flex-row d-flex">
            <a href="https://apps.apple.com/us/app/lunch-with-4/id1476042554?ls=1">
              <div className="d-flex flex-row">
                <div>
                  <i className="fa fa-apple" aria-hidden="true"></i>
                </div>
                <div className="desc">
                  <p>
                    <div className="available">Available</div>
                    <div className="on-app-store">on App Store</div>
                  </p>
                </div>
              </div>
            </a>
          </div>
          <div className="buttons dark flex-row d-flex">
            <a href="https://play.google.com/store/apps/details?id=com.mobile.lw4ipmobileapp">
              <div className="d-flex flex-row">
                <div>
                  <i className="fa fa-android" aria-hidden="true"></i>
                </div>
                <div className="desc">
                  <p>
                    <div className="available">Available</div>
                    <div className="on-app-store">on Play Store</div>
                  </p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default DownloadButtons;
