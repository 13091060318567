const stripeConfig = {
  dev: {
    public_key: "pk_test_hyV3ImfnkakhCRP48i864i7g00xdNjau60"
  },

  qa: {
    public_key: "pk_test_hyV3ImfnkakhCRP48i864i7g00xdNjau60"
  },

  prod: {
    public_key: "pk_live_C7bzPGCzhK90rWiXOrAwGsfV"
  }
};

export default stripeConfig.qa;
