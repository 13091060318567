import React, { useContext } from "react";
import Card from "../../components/Card";
import { LunchForCauseDetailContext } from "../../container/CardContainer";
import { LunchForCausePublishedDetails } from "../../components/LunchForCausePublishedDetails";
import { HostCharityAndCelebrityDetails } from "../../components/HostCharityAndCelebrityDetails";
import HostCharityAndCelebrityForRaffleDrawnAndClosed from "../../components/HostCharityAndCelebrityForRaffleDrawnAndClosed";
import StatusImage from "../StatusImage";
import WinnerBackgroundImage from "../../Assets/images/web-bg.png";
import moment from "moment";
import { isMobileOnly } from "react-device-detect";
import InfoCard from "../InfoCard";
import HeartImage from "../../Assets/images/charity-added@2x.png";
import StarImage from "../../Assets/images/celebrity-added@2x.png";
import * as Constant from "./constant";
import "./style.scss";
import Button from "../Button";
import DownloadButtons from "../DownloadButtons";

const GetStatusAndRender = () => {
  const detail = useContext(LunchForCauseDetailContext);
  const { dispatch } = detail;
  const {
    data: {
      getLunchForCauseDetails: { data, code },
    },
  } = detail;

  if (code === "200") {
    if (data.publishStatus === "unPublished") {
      return <>This page is not accessible if lunch is Unpublished</>;
    } else if (data.cancelledAt) {
      return (
        <>
          {isMobileOnly && (
            <div className="flex-column-container">
              <DownloadButtons title="Download Android and iOS app" />
            </div>
          )}
          {isMobileOnly && (
            <div className="info-card-container">
              <InfoCard
                className="charity-card"
                name={
                  data.charityContactDetailsForLunch &&
                  data.charityContactDetailsForLunch.charity.title
                }
                bgColor="#DA453C"
                title={Constant.Charity.toUpperCase()}
                imageSrc={HeartImage}
                imageWidth="16px"
                imageHeight="15px"
                href={data.charityContactDetailsForLunch.charity.url}
              ></InfoCard>
              <InfoCard
                className="celebrity-card"
                cardTitle="celebrity-card-width"
                name={data.celebrity.name}
                bgColor="#FF9738"
                title={Constant.Celebrity.toUpperCase()}
                imageSrc={StarImage}
                imageWidth="16px"
                imageHeight="15px"
              ></InfoCard>
            </div>
          )}
          <Card className="card-style">
            <HostCharityAndCelebrityForRaffleDrawnAndClosed cancelled />
          </Card>
          <StatusImage
            status="cancelled"
            className="background-image-cancelled"
          />
        </>
      );
    } else if (
      data.publishStatus === "published" &&
      moment(data.raffleEndDate).isBefore(moment().toISOString())
    ) {
      return (
        <>
          {isMobileOnly && (
            <div className="flex-column-container">
              <DownloadButtons title="Download Android and iOS app" />
            </div>
          )}
          {isMobileOnly && (
            <div className="info-card-container">
              <InfoCard
                className="charity-card"
                name={
                  data.charityContactDetailsForLunch &&
                  data.charityContactDetailsForLunch.charity.title
                }
                bgColor="#DA453C"
                title={Constant.Charity.toUpperCase()}
                imageSrc={HeartImage}
                imageWidth="16px"
                imageHeight="15px"
                href={data.charityContactDetailsForLunch.charity.url}
              ></InfoCard>
              <InfoCard
                className="celebrity-card"
                cardTitle="celebrity-card-width"
                name={data.celebrity.name}
                bgColor="#FF9738"
                title={Constant.Celebrity.toUpperCase()}
                imageSrc={StarImage}
                imageWidth="16px"
                imageHeight="15px"
              ></InfoCard>
            </div>
          )}
          <Card className="card-style">
            <HostCharityAndCelebrityForRaffleDrawnAndClosed closed />
          </Card>
          <StatusImage
            image={WinnerBackgroundImage}
            status="closed"
            className="background-image-winner"
          />
        </>
      );
    } else if (data.publishStatus === "published") {
      return (
        <>
          {isMobileOnly && (
            <Button
              className="make-payment-button"
              onClick={() =>
                dispatch({ type: "BOTTOM_SHEET_OPEN", payload: true })
              }
            >
              {Constant.makePayment}
            </Button>
          )}
          {isMobileOnly && <HostCharityAndCelebrityDetails />}
          {isMobileOnly && (
            <div className="info-card-container">
              <InfoCard
                className="charity-card"
                name={
                  data.charityContactDetailsForLunch &&
                  data.charityContactDetailsForLunch.charity.title
                }
                bgColor="#DA453C"
                title={Constant.Charity.toUpperCase()}
                imageSrc={HeartImage}
                imageWidth="16px"
                imageHeight="15px"
                href={data.charityContactDetailsForLunch.charity.url}
              ></InfoCard>
              <InfoCard
                className="celebrity-card"
                cardTitle="celebrity-card-width"
                name={data.celebrity.name}
                bgColor="#FF9738"
                title={Constant.Celebrity.toUpperCase()}
                imageSrc={StarImage}
                imageWidth="16px"
                imageHeight="15px"
              ></InfoCard>
            </div>
          )}
          <Card>
            <LunchForCausePublishedDetails />
          </Card>
          {!isMobileOnly && <HostCharityAndCelebrityDetails />}
        </>
      );
    } else if (data.publishStatus === "raffleDrawn") {
      return (
        <>
          {isMobileOnly && (
            <div className="flex-column-container">
              <DownloadButtons title="Download Android and iOS app" />
            </div>
          )}
          {isMobileOnly && (
            <div className="info-card-container">
              <InfoCard
                className="charity-card"
                name={
                  data.charityContactDetailsForLunch &&
                  data.charityContactDetailsForLunch.charity.title
                }
                bgColor="#DA453C"
                title={Constant.Charity.toUpperCase()}
                imageSrc={HeartImage}
                imageWidth="16px"
                imageHeight="15px"
                href={data.charityContactDetailsForLunch.charity.url}
              ></InfoCard>
              <InfoCard
                className="celebrity-card"
                cardTitle="celebrity-card-width"
                name={data.celebrity.name}
                bgColor="#FF9738"
                title={Constant.Celebrity.toUpperCase()}
                imageSrc={StarImage}
                imageWidth="16px"
                imageHeight="15px"
              ></InfoCard>
            </div>
          )}
          <Card className="card-style">
            <HostCharityAndCelebrityForRaffleDrawnAndClosed raffleDrawn />
          </Card>
          <StatusImage
            image={WinnerBackgroundImage}
            name={data.raffleWinner}
            status="raffleDrawn"
            className="background-image-winner"
          />
        </>
      );
    } else if (data.publishStatus === "closed") {
      return <>This page is not accessible if lunch is Completed.</>;
    }
  } else {
    return <>This lunch is not available</>;
  }
};

export default GetStatusAndRender;
