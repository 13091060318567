import gql from "graphql-tag";

const detailQuery = gql`
  query getLunchForCauseDetails($lunchForCauseId: Int!) {
    getLunchForCauseDetails(lunchForCauseId: $lunchForCauseId) {
      code
      message
      success
      data {
        id
        title
        benefit
        possibleRaffleAmount
        raffleEndDate
        raffleDrawDate
        cancelledAt
        closedAt
        publishedAt
        publishStatus
        timezone
        hostId
        charityContactDetailsId
        celebrityId
        activeLunchCauseVerificationStatusId
        hostedBy {
          firstName
          lastName
        }
        contributorsCount
        charityContactDetailsForLunch {
          charity {
            title
            url
          }
        }
        celebrity{
            name
        }
        raffleWinner{
          firstName
          lastName
        }
        imagePath
      }
    }
  }
`;

const initiateRafflePurchaseProcess = gql`
  mutation initiateRafflePurchaseProcess($input:ExternalUserInput!){
    initiateRafflePurchaseProcess(input:$input){
      code
      message
      success
      errorCode
      data
    }
  }
`;

export { detailQuery, initiateRafflePurchaseProcess };
