import React from "react";
import HostImage from "../../Assets/images/winner-no-profile@2x.png";
import HostImageForMobile from "../../Assets/images/winner-no-profile-mobile.png";
import "./style.scss";
const CircularImage = () => (
  <div className="circular-image">
    <picture>
      <source
        srcSet={`${HostImageForMobile}`}
        media="all and (max-width:479px)"
        alt="host"
        type="image/png"
      />
      <img src={HostImage} alt="host" className="winner-image" />
    </picture>
  </div>
);

export default CircularImage;
