import React, { useContext } from "react";
import { LunchForCauseDetailContext } from "../../container/CardContainer";
import InfoCard from "../InfoCard";
import * as Constant from "./constant.js";
import HeartImage from "../../Assets/images/charity-added@2x.png";
import StarImage from "../../Assets/images/celebrity-added@2x.png";
import "./style.scss";
import HostImage from "../HostImage";
import { isMobileOnly } from "react-device-detect";
import DownloadButtons from "../DownloadButtons";

const OpportunityLine = ({ celebrityName, className }) => (
  <div className={`opportunity-line-container ${className ? className : ""}`}>
    <div className="opportunity-line">
      {`You have the opportunity to be flown out to have Lunch with "${celebrityName}". `}
    </div>
    <div className="contribute-line">
      Contribute any amount to be automatically entered to win.{" "}
    </div>
    <div className="terms-and-service">
      Click here for{" "}
      <span className="terms-and-conditions">
        <a
          className="terms-and-conditions"
          href="https://www.lunchwith4interestingpeople.com/termsofservice.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms & Conditions
        </a>
      </span>
    </div>
  </div>
);

const HostCharityAndCelebrityDetails = () => {
  const {
    data: {
      getLunchForCauseDetails: { data },
    },
  } = useContext(LunchForCauseDetailContext);
  return (
    <div className="host-charity-celebrity-container">
      {!isMobileOnly && <HostImage />}
      {!isMobileOnly && (
        <div className="info-card-container">
          <InfoCard
            className="charity-card"
            name={
              data.charityContactDetailsForLunch &&
              data.charityContactDetailsForLunch.charity.title
            }
            bgColor="#DA453C"
            title={Constant.Charity.toUpperCase()}
            imageSrc={HeartImage}
            imageWidth="16px"
            imageHeight="15px"
            href={data.charityContactDetailsForLunch.charity.url}
          ></InfoCard>
          <InfoCard
            className="celebrity-card"
            cardTitle="celebrity-card-width"
            name={data.celebrity.name}
            bgColor="#FF9738"
            title={Constant.Celebrity.toUpperCase()}
            imageSrc={StarImage}
            imageWidth="16px"
            imageHeight="15px"
          ></InfoCard>
        </div>
      )}
      {isMobileOnly ? (
        <>
          <DownloadButtons title="Download Android and iOS app" />
          <OpportunityLine
            celebrityName={data.celebrity.name}
            className="padding-16"
          />
        </>
      ) : (
        <>
          <OpportunityLine celebrityName={data.celebrity.name} />
          <DownloadButtons title="Download Android and iOS app" />
        </>
      )}
    </div>
  );
};

export { HostCharityAndCelebrityDetails, OpportunityLine };
