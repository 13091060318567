import React, { useContext } from "react";
import "./style.scss";
import * as Constant from "./constant";
import { PaymentContext } from "../../container/PaymentContainer";
import { useLocation } from "react-router-dom";

const Title = () => {
  const paymentData = useContext(PaymentContext);
  const {search}= useLocation()
  const queryParams = new URLSearchParams(search);
  const status = queryParams.get("status");
  const {
    getCheckoutSessionMetaData: {
      data: { title }
    }
  } = paymentData;

  return (
    <div className="lunch-title-container">
      <div className="lunch-title-container-paymentTitle">
        {status === "success" ? Constant.paymentTitle : Constant.paymentFailedTitle}
        <span className="lunch-title-container-name">{`"${title.toUpperCase()}"`}</span>
      </div>
    </div>
  );
};

export default Title;
