const modalReducer = (state, action) => {
  switch (action.type) {
    case "OPEN":
      return { ...state, open: action.payload };
    case "CLOSE":
      return { ...state, open: action.payload };
    default:
      return state;
  }
};

export default modalReducer;
