import React, { useContext } from "react";
import { LunchForCauseDetailContext } from "../../container/CardContainer";
import "./style.scss";

const BottomSheet = ({ children }) => {
  const detail = useContext(LunchForCauseDetailContext);
  const { state, dispatch } = detail;
  return (
    <div
      className={`bottom-sheet-container ${
        state.bottom_sheet_open ? "__bottom-sheet-open__ " : "__bottom-sheet-close__"
      }`}
    >
      {state.bottom_sheet_open ? (
        <div className="bottom-sheet-container-padding">{children(state,dispatch)}</div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default BottomSheet;
