import React from "react";
import LogoImage from "../../Assets/images/logo.png";
import "./style.scss";

const Logo = () => (
  <div className="logo">
    <a
      href="http://lunchwith4interestingpeople.com/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={LogoImage} alt="logo" className="logo-image" />
    </a>
  </div>
);

export default Logo;
