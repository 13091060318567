import React, { useContext } from "react";
import { LunchForCauseDetailContext } from "../../container/CardContainer";
import BackButtonImage from "../../Assets/images/back-arrow.png";
import "./style.scss";

const ModalForMobile = ({ children }) => {
  const { state, dispatch } = useContext(LunchForCauseDetailContext);
  return (
    <div className={`modal-for-mobile-container ${state.bottom_sheet_open_user_modal ? "__modal-for-mobile-open__":"__modal-for-mobile-close__"}`}>
      <div className="modal-for-mobile-container-back-button">
        <img src={BackButtonImage} alt="back" onClick={()=> dispatch({type:"BOTTOM_SHEET_CLOSE_USER_MODAL", payload:false})}/>
      </div>
      <div className="modal-for-mobile-container-padding">
        {children(state, dispatch)}
      </div>
    </div>
  );
};

export default ModalForMobile;
