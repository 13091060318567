import React from "react";
import Loading from "../Loading";

const WindowLoading = () => (
  <Loading>
    <Loading.Nav />
    <Loading.Main />
  </Loading>
);

export default WindowLoading;
