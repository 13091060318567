const awsConfig = {
  dev: {
    API: {
      aws_appsync_graphqlEndpoint:
        "https://jv3zfgnfmrfkzczyvq2ocsrhiq.appsync-api.us-west-2.amazonaws.com/graphql",
      aws_appsync_region: "us-west-2",
      aws_appsync_authenticationType: "AWS_IAM",
      credentials: { "accessKeyId": 'AKIA3MDNXILNS57EJHOD', "secretAccessKey": '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', "region": "us-west-2"}
    },
    Storage: {
      AWSS3: {
        bucket: "dev-lw4ip-profile-images", //REQUIRED -  stage
        region: "us-west-2" //OPTIONAL -  Amazon service region
      }
    }
  },
  qa: {
    API: {
      aws_appsync_graphqlEndpoint:
        "https://uroswgc2xfgytnabqxme3mha3e.appsync-api.us-east-1.amazonaws.com/graphql",
      aws_appsync_region: "us-east-1",
      aws_appsync_authenticationType: "AWS_IAM",
      credentials: { "accessKeyId": 'AKIA3MDNXILNS57EJHOD', "secretAccessKey": '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', "region": "us-east-1"}
    },
    Storage: {
      AWSS3: {
        bucket: "qa-lw4ip-profile-images", //REQUIRED - stage
        region: "us-east-1" //OPTIONAL - Amazon service region
      }
    },
    Auth: {
      identityPoolId: "us-east-1:4619416f-8173-4077-8372-0204275794a1",
      region: "us-east-1",
      userPoolId: 'us-east-1_wasXWCwEe',
      userPoolWebClientId: '4jng61jsamjf9kdqoq5lrmk0na',
    }
  },
  prod: {
    API: {
      aws_appsync_graphqlEndpoint:
        "https://pw4ykobt2fepjdnzqmpclnlc6a.appsync-api.us-west-2.amazonaws.com/graphql",
      aws_appsync_region: "us-west-2",
      aws_appsync_authenticationType: "AWS_IAM",
      credentials: { "accessKeyId": 'AKIA3MDNXILNS57EJHOD', "secretAccessKey": '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', "region": "us-west-2"}
    },
    Storage: {
      AWSS3: {
        bucket: "lw4ip-sam-stage", //REQUIRED -  Prod
        region: "us-west-2" //OPTIONAL -  Amazon service region
      }
    }
  }
};

export default awsConfig.qa;
