import React from "react";
import ExploreMoreCauseImage from "../../Assets/images/all-causes-list.png";
import exploreMoreCause from "./constant";
import "./style.scss";
const ExploreMoreCause = () => {
  return (
    <div className="explore-more-causes">
      <div className="explore-more-causes-detail">
        <div className="explore-more-causes-header">
          {exploreMoreCause.header}
        </div>
        <div className="explore-more-causes-description">
          {exploreMoreCause.description}
        </div>
      </div>
      <img src={ExploreMoreCauseImage} alt="explore" className="explore-image"/>
    </div>
  );
};

export default ExploreMoreCause;
