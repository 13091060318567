import React from "react";
import RaffleCancelledImage from "../../Assets/images/raffle-cancelled.png";
import "./style.scss";
const CancelledImage = () => (
  <div className="cancel-image-container">
      <img src={RaffleCancelledImage} alt="Cancelled" className="cancel-image"/>
  </div>
);

export default CancelledImage;
