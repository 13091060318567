import gql from "graphql-tag";

const getCheckoutSessionMetaData = gql`
query getCheckoutSessionMetaData ($SessionId: String!) {
getCheckoutSessionMetaData (sessionId: $SessionId) {
code
message
success
data{
amount
lunchForCauseId
title
userName
transactionId
isParticipatingInRaffle
currency
}
}
}
`;

export default getCheckoutSessionMetaData;
