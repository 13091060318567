import React from "react";
import "./style.scss";


const restrictMinus = (e) => {
  const keyCode = e.keyCode ? e.keyCode : e.which;
  if(keyCode === 45){
    e.preventDefault();
  }
}

const Input = ({ value, handleInput, amount, error }) => {
  return (
    <div className="amount-input-container">
      <input
        id={`amount-input`}
        type="number"
        value={value}
        onChange={handleInput}
        className={error === true ?'error':''}
        placeholder="Enter Amount"
        min={1}
        onKeyPress={restrictMinus}
      />
    </div>
  );
};

export default Input;
