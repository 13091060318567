import React, { createContext, useReducer } from "react";
import { Query } from "react-apollo";
import { detailQuery } from "../../Queries/detailQuery";
import Loading from "../../components/Loading";
import { withRouter } from "react-router-dom";
import GlobalState from "../../GlobalState";
import bottomSheetReducer from "../../Reducer/bottomSheetReducer";
const LunchForCauseDetailContext = createContext();

let CardContainer = ({ children, location }) => {
  const url = new URLSearchParams(location.search);
  const causeId = parseInt(url.get("causeid"));
  const [state, dispatch] = useReducer(bottomSheetReducer, GlobalState);
  return (
    <Query
      query={detailQuery}
      fetchPolicy="no-cache"
      variables={{
        lunchForCauseId: causeId,
      }}
    >
      {({ loading, error, data }) => {
        if (loading) {
          return <Loading.Card />;
        } else if (data) {
          const store = {
            data,
            state,
            dispatch,
          };
          return (
            <LunchForCauseDetailContext.Provider value={store}>
              {children}
            </LunchForCauseDetailContext.Provider>
          );
        } else if (error) {
          console.log(error)
          // setTimeout(
          //   () =>
          //     (window.location.href =
          //       "https://www.lunchwith4interestingpeople.com/index.html"),
          //   600
          // );
        return <></>;
        }
      }}
    </Query>
  );
};

CardContainer = withRouter(CardContainer);

export { CardContainer, LunchForCauseDetailContext };
