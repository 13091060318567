import React, { useContext } from "react";
import PaymentCardBackground from "../../Assets/images/payment-successfull-card@2x.png";
import * as Constant from "./constant";
import PaymentFailedImage from "../../Assets/images/payment-failed@2x.png";
import PaymentSuccessImage from "../../Assets/images/green-tick@2x.png";
import { PaymentContext } from "../../container/PaymentContainer";
import "./style.scss";
import { useLocation } from "react-router-dom";
const PaymentCard = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const status = queryParams.get("status");
  const paymentData = useContext(PaymentContext);
  const {
    getCheckoutSessionMetaData: {
      data: { amount, userName, transactionId }
    }
  } = paymentData;

  return (
    <div
      style={{ backgroundImage: `url(${PaymentCardBackground})` }}
      className="payment-card"
    >
      <div className="payment-card-amount-paid-by">
        {Constant.amountPaidBy}
        {userName}
      </div>
      <div className="payment-card-amount">
        {status === Constant.success ? (
          <img
            src={PaymentSuccessImage}
            alt="success"
            className="payment-card-amount-paymentImage"
          />
        ) : (
          <img
            src={PaymentFailedImage}
            alt="failed"
            className="payment-card-amount-paymentImage"
          />
        )}
        {status === Constant.success ? `$${amount}` : `$0`}
      </div>
      <div className="payment-card-transaction-id">
        {status === Constant.success ? (
          `${Constant.transactionId}${transactionId}`
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default PaymentCard;
