import React from "react";
import GetAccessToAllYourCausesImage from "../../Assets/images/your-cuaseslist-img.png";
import getAccessToAll from "./constant";
import "./style.scss";
import { isMobileOnly } from "react-device-detect";

const GetAccessToAllYourCauses = () => {
  console.log(isMobileOnly);
  return (
    <>
      {!isMobileOnly ? (
        <div className="get-access-to-all-causes">
          <div
            style={{ backgroundImage: `url(${GetAccessToAllYourCausesImage})` }}
            className="get-access-to-all-causes-background"
          >
            <div className="get-access-to-all-causes-background-text-container">
              <div className="width-300">
                <div className="width-300-header">{getAccessToAll.header}</div>
                <div className="width-300-description">
                  {getAccessToAll.description}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="get-access-to-all-causes">
          <div className="get-access-to-all-causes-background-text-container">
            <div className="width-300">
              <div className="width-300-header">{getAccessToAll.header}</div>
              <div className="width-300-description">
                {getAccessToAll.description}
              </div>
            </div>
          </div>
          <div
            style={{ backgroundImage: `url(${GetAccessToAllYourCausesImage})` }}
            className="get-access-to-all-causes-background"
          ></div>
        </div>
      )}
    </>
  );
};

export default GetAccessToAllYourCauses;
