import React from "react";
import "./style.scss";

const LeftContainer = ({ children }) => {
  return (
    <>
      <div className="left-container">{children}</div>
    </>
  );
};

export { LeftContainer };
